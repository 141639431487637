import axios from "axios";

axios.defaults.withCredentials = false;

const instance = axios.create({
    timeout: 1000 * 5,
});

const getFullApiPath = (path) => process.env.VUE_APP_API_ORIGIN + path;

instance.interceptors.request.use(
    (config) => {
        config.headers["Authorization"] =
            "Bearer " + localStorage.getItem("token") || "";

        if (config.data) {
            if (config.data.token) {
                config.headers.token = config.data.token;
                delete config.data.token;
            }
        }

        if (process.env.NODE_ENV === "production") {
            config.url = getFullApiPath(config.url);
        }
        return config;
    }
    // () => {
    //     return Promise.reject({
    //         errorMsg: "网络异常"
    //     });
    // }
);

instance.interceptors.response.use(
    (response) => {
        if (response.status === 200 || response.status === 304) {
            if (response.data.error && response.data.error.code === 4001) {
                localStorage.removeItem("token");
                window.location.href = "/login";

                return Promise.reject({
                    errorMsg: "token 过期",
                });
            }
            if (response.data.error && response.data.error.code === 4005) {
                localStorage.removeItem("token");
                window.location.href = "/login";

                return Promise.reject({
                    errorMsg: "token 不存在",
                });
            }
            response.data.contentType = response.headers["content-type"];
            return Promise.resolve(response.data);
        }
        return Promise.reject({
            errorMsg: "111网络异常",
        });
    },
    (error) => {
        return Promise.reject(error.response);
    }
);

export default instance;
